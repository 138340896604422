#works {
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.worksTitle {
  margin: 1rem 0;
  font-size: 3rem;

  text-shadow: 2px 2px 8px #000000;
}

.worksTitle2 {
  margin: 1rem 0;
  font-size: 1.5rem;

  text-shadow: 2px 2px 8px #000000;
}

.worksDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 45rem;
}

.worksImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100rem;
  flex-wrap: wrap;
}

.worksImg {
  object-fit: cover;
  height: 20rem;
  margin: 0.5rem;
  transition: transform 0.2s;
}

.worksImg:hover {
  transform: scale(1.05);
  border-style: outset;
  border-color: #37a12b;
}

.worksBtn {
  padding: 0.5rem 2.5rem;
  border: none;
  background: white;
  border-radius: 2rem;
  font-size: 1rem;
  margin: 3rem 0;
}

@media screen and (max-width: 585px) {
  .worksImg {
    height: 48vw;
  }
}
