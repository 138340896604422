.navbar {
  background: rgb(30, 30, 30);
  height: 8.5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
}

.logo {
  object-fit: cover;
  height: 10rem;
  width: 10rem;
}

.desktopMenuListItem {
  margin: 1rem;
  cursor: pointer;
}

.desktopMenuListItem:hover {
  color: #37a12b;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #37a12b;
}

.desktopMenuBtn {
  background: white;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 0 1rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.desktopMenuBtn:hover {
  background: #37a12b;
  transform: scale(1.1);
}

.desktopMenuImg {
  object-fit: cover;
  height: 2rem;
  width: 2rem;
  margin: 1rem;
}

.active {
  color: green;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #37a12b;
}

.mobMenu {
  display: none;
  object-fit: cover;
  height: 4rem;
}

.navMenu {
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background: rgb(40, 40, 40);
  border-radius: 1rem;
}

.listItem {
  color: white;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
}

@media screen and (max-width: 720px) {
  .mobMenu {
    display: flex;
  }

  .desktopMenu {
    display: none;
  }

  .desktopMenuBtn {
    display: none;
  }
}
